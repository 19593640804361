import React from "react"
import styles from "./sns.module.scss"

const SNS = () => {
  const items = []
  const data = {
    pixiv: {
      id: "46348117",
      url: "https://www.pixiv.net/en/users/",
      image: "logo-pixiv.svg",
    },
    twitter: {
      id: "qlestcom",
      url: "https://twitter.com/",
      image: "logo-twitter.svg",
    },
    youtube: {
      id: "UCi05P9riHrN8ZzmVBsmZ_7g",
      url: "https://www.youtube.com/channel/",
      image: "logo-youtube.svg",
    },
  }
  for (const key in data) {
    items.push(
      <li key={key}>
        <a
          className={styles.label}
          href={data[key].url + data[key].id}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={styles.icon} src={`/${data[key].image}`} alt="" />
        </a>
      </li>
    )
  }
  return <ul className={styles.labels}>{items}</ul>
}

export default SNS
