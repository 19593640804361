import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SNS from "../components/SNS/sns"
import styles from "./index.module.scss"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <article className={styles.article}>
        <section>
          <h2>SNS</h2>
          <div>
            <SNS />
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default IndexPage
